<template>
  <div class="mt-4">
    <div class="my-4 d-flex justify-content-between">
      <h1 class="page-title whitespace-wrapper">
        <b-icon icon="server" />
        {{ qre.name }}
      </h1>

      <div class="d-flex align-items-center align-content-center">
        <b-button-group style="border-radius: 50rem !important">
          <b-button data-cy="single-qre-back" @click="$router.go(-1)">
            <b-icon-arrow90deg-left class="mr-2" />
            {{ $t("back") }}
          </b-button>

          <span id="qre-warning">
            <b-button
              v-if="qre.status !== 'TRANSITIONING' && qre.latestError !== null"
              disabled
              style="border-radius: 0 !important"
              variant="warning"
            >
              <b-icon icon="exclamation-triangle-fill" />
            </b-button>
          </span>
          <b-tooltip target="qre-warning" triggers="hover">
            {{ qre.latestError }}
          </b-tooltip>
          <b-dropdown
            id="qre-btn-status"
            :text="qre.status"
            :variant="statusVariant(qre.status)"
            data-cy="qre-status-dropdown"
            right
            split
            @click="refreshStatus"
          >
            <b-dropdown-item
              v-if="qre.status === 'RUNNING'"
              :disabled="
                loadingStatus ||
                statusVariant(qre.status) === 'warning' ||
                statusVariant(qre.status) === 'secondary'
              "
              @click="changeQreStatus"
            >
              <b-icon-stop-fill class="mr-1" />
              {{ $t("stop") }}
            </b-dropdown-item>

            <b-dropdown-item
              v-else
              :disabled="
                loadingStatus ||
                statusVariant(qre.status) === 'warning' ||
                statusVariant(qre.status) === 'secondary'
              "
              @click="changeQreStatus"
            >
              <b-icon-play-fill class="mr-1" />
              {{ $t("start") }}
            </b-dropdown-item>
            <b-dropdown-item :disabled="loadingStatus" @click="restartQre(qre)">
              <b-icon-bootstrap-reboot class="mr-1" />
              {{ $t("restart") }}
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            :disabled="
              loadingStatus ||
              statusVariant(qre.status) === 'warning' ||
              statusVariant(qre.status) === 'secondary'
            "
            :split-to="`/admin/qres/${this.$route.params.id}/edit`"
            data-cy="single-qre-dropdown"
            right
            split
          >
            <template #button-content>
              <b-icon-pencil class="mr-1" />
              {{ $t("editQRE.title") }}
            </template>

            <b-dropdown-item :href="qre.url" target="_blank">
              <b-icon class="mr-1" icon="book" />
              {{ $t("qres.openNotebook") }}
            </b-dropdown-item>

            <b-dropdown-item @click="openModalForFileUpload">
              <b-icon class="mr-1" icon="upload" />
              {{ $t("uploadFile.title") }}
            </b-dropdown-item>

            <b-dropdown-item
              :disabled="snapshots.length - 1 === qre.snapshotLimit"
              @click="resetSnapshotFormModal"
            >
              <b-icon-camera class="mr-1" />
              {{ $t("singleQRE.snapshotDropdown") }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="this.$store.getters.isAdmin"
              :disabled="
                loadingStatus ||
                statusVariant(qre.status) === 'warning' ||
                statusVariant(qre.status) === 'secondary' ||
                statusVariant(qre.status) === 'danger'
              "
              :to="`/admin/qres/${qreId}/terminal/`"
              data-cy="open-terminal"
            >
              <b-icon class="mr-1" icon="terminal" />
              {{ $t("rescueConsole.title") }}
            </b-dropdown-item>

            <b-dropdown-item :to="`/admin/qres/${qreId}/logs`">
              <b-icon class="mr-1" icon="file-text" />
              {{ $t("qres.logs") }}
            </b-dropdown-item>

            <b-dropdown-item data-cy="delete-qre-button" @click="deleteModal">
              <b-icon-trash class="mr-1" />
              {{ $t("delete") }}
            </b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </div>

      <b-tooltip
        :disabled="
          loadingStatus ||
          statusVariant(qre.status) === 'warning' ||
          statusVariant(qre.status) === 'secondary'
        "
        target="qre-btn-status"
        triggers="hover"
      >
        Click to refresh
      </b-tooltip>
    </div>
    <b-row class="mt-4">
      <b-col lg="3" md="12">
        <b-overlay :opacity="0.95" :show="loading" rounded="sm">
          <b-card border-variant="light" class="shadow-sm card-rounded mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <p class="mb-0 custom-card-title">{{ $t("singleQRE.cores") }}</p>
            </div>
            <div class="mt-2">
              <p class="card-info mb-0" data-cy="qre-info-cores">
                {{ qre.cores }}
              </p>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col lg="3" md="12">
        <b-overlay :opacity="0.95" :show="loading" rounded="sm">
          <b-card border-variant="light" class="shadow-sm card-rounded mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <p class="mb-0 custom-card-title">{{ $t("singleQRE.memory") }}</p>
            </div>
            <div class="mt-2">
              <p class="card-info mb-0" data-cy="qre-info-memory">
                {{ qre.memoryInBytes }}
              </p>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col lg="3" md="12">
        <b-overlay :opacity="0.95" :show="loading" rounded="sm">
          <b-card border-variant="light" class="shadow-sm card-rounded mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <p class="mb-0 custom-card-title">
                {{ $t("singleQRE.storage") }}
              </p>
            </div>
            <div class="mt-2">
              <p class="card-info mb-0" data-cy="qre-info-storage">
                {{ qre.storageInBytes }}
              </p>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col lg="3" md="12">
        <b-overlay :opacity="0.95" :show="loading" rounded="sm">
          <b-card border-variant="light" class="shadow-sm card-rounded mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <p class="mb-0 custom-card-title">
                {{ $t("singleQRE.shm") }}
              </p>
            </div>
            <div class="mt-2">
              <p class="card-info mb-0" data-cy="qre-info-shm">
                {{ qre.shmInBytes }}
              </p>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" md="12">
        <b-overlay :opacity="0.95" :show="loading" rounded="sm">
          <b-card border-variant="light" class="shadow-sm card-rounded mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <p class="mb-0 custom-card-title">
                {{ $t("singleQRE.cpuSetCpus") }}
              </p>
            </div>
            <div class="mt-2">
              <p class="card-info mb-0">
                {{ qre.cpuSetCpus }}
              </p>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col lg="6" md="12">
        <b-overlay :opacity="0.95" :show="loading" rounded="sm">
          <b-card border-variant="light" class="shadow-sm card-rounded mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <p class="mb-0 custom-card-title">{{ $t("singleQRE.agent") }}</p>
            </div>
            <div class="mt-2">
              <p class="card-info mb-0">
                <router-link
                  :to="`/admin/nodes/${agent.id}`"
                  class="agent-link"
                >
                  {{ agent.name }}
                </router-link>
              </p>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col lg="12" md="12">
        <b-overlay :opacity="0.95" :show="loading" rounded="sm">
          <b-card border-variant="light" class="shadow-sm card-rounded mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <p class="custom-card-title">
                {{ $t("singleQRE.assignedGpus") }}
              </p>
            </div>

            <b-table
              :busy="loading"
              :fields="fieldsGPUs"
              :items="qre.gpus"
              :per-page="0"
              borderless
              class="mb-0 md"
              data-cy="gpu-single-qre-page"
              fixed
              head-variant="light"
              small
              striped
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-items-center mr-2" />
                  <strong> {{ $t("loading") }} </strong>
                </div>
              </template>

              <template #cell(name)="data">
                <p class="gpu-name mb-0">
                  {{ data.value }}
                </p>
              </template>

              <template #cell(status)="data">
                <GpuIcon
                  v-b-tooltip.hover
                  :color="data.value === 'BROKEN' ? '#fa5c7c' : '#6FBF94'"
                  :height="25"
                  :title="
                    data.value === 'BROKEN'
                      ? 'GPU is broken'
                      : 'GPU is connected'
                  "
                  :width="25"
                />
              </template>

              <template #cell(empty)="data">
                <b-button
                  v-b-tooltip.hover
                  :title="data.item.uuid"
                  pill
                  size="sm"
                  variant="light"
                  @click="copyToClipboard(data.item.uuid)"
                >
                  <b-icon-clipboard />
                </b-button>
              </template>
            </b-table>
            <b-card-text
              v-if="qre.gpus.length === 0 && !loading"
              class="text-center my-4"
            >
              No assigned GPUs
            </b-card-text>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <ManageUsersCard
      :title-for-access="$t('singleQRE.userAccess')"
      v-bind:choices="userAccessOptions"
      v-bind:loading="loadingUserAccess"
      v-bind:model="userAccess"
      v-bind:qre-status="qre.status"
      @on-option-click="giveQreAccess"
      @on-remove-click="removeQreAccess"
    />

    <ManageUsersCard
      :title-for-access="$t('singleQRE.rescueConsoleAccess')"
      v-bind:choices="consoleAccessOptions"
      v-bind:loading="loadingConsoleAccess"
      v-bind:model="consoleAccess"
      v-bind:qre-status="qre.status"
      @on-option-click="giveConsoleAccess"
      @on-remove-click="removeConsoleAccess"
    />

    <b-overlay :opacity="0.95" :show="loading" rounded="sm">
      <b-card border-variant="light" class="mt-4 shadow-sm card-rounded">
        <div class="d-flex align-items-center justify-content-between">
          <p class="custom-card-title">{{ $t("singleQRE.accessLog") }}</p>
        </div>
        <b-table
          :busy="loadingAccess"
          :current-page="currentPage"
          :fields="fieldsUsers"
          :head-variant="'light'"
          :items="activeLoggedIn"
          :per-page="0"
          borderless
          class="mb-0"
          data-cy="admin-active-loggedin-users-table"
          fixed
          small
          striped
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-2" />
              <strong> {{ $t("loading") }} </strong>
            </div>
          </template>

          <template #head(active)>
            {{ $t("singleQRE.active") }}
            <b-icon-info-circle class="ml-1" />
            <b-tooltip target="id-active" triggers="hover ">
              {{ $t("singleQRE.activeInfo") }}
            </b-tooltip>
          </template>

          <template #cell(active)="data">
            <p class="h5">
              <b-icon-check v-if="data.item.active" variant="success" />
            </p>
          </template>

          <template #head(loggedIn)>
            {{ $t("singleQRE.loggedin") }}

            <b-icon-info-circle class="ml-1" />
            <b-tooltip target="id-loggedin" triggers="hover ">
              {{ $t("singleQRE.loggedinInfo") }}
            </b-tooltip>
          </template>

          <template #cell(loggedIn)="data">
            <p class="h5">
              <b-icon-check v-if="data.item.loggedIn" variant="success" />
            </p>
          </template>
        </b-table>
      </b-card>
    </b-overlay>

    <b-overlay :opacity="0.95" :show="loading" rounded="sm">
      <b-card border-variant="light" class="mt-4 shadow-sm card-rounded">
        <div class="d-flex align-items-center justify-content-between">
          <p class="custom-card-title">{{ $t("singleQRE.snapshotsTable") }}</p>
        </div>
        <b-table
          :busy="loadingSnapshots && !loading"
          :current-page="currentPage"
          :fields="fields"
          :items="snapshots"
          :per-page="0"
          borderless
          class="mb-0 md"
          fixed
          head-variant="light"
          small
          striped
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-items-center mr-2" />
              <strong> {{ $t("loading") }} </strong>
            </div>
          </template>

          <template #cell(name)="data">
            {{ data.value }}

            <b-icon-tag-fill
              v-if="data.item.inUse"
              :title="$t('singleQRE.activeSnapshot')"
            />
          </template>

          <template #cell(comment)="data">
            <div
              v-b-tooltip.hover
              :title="data.value.length > 20 ? data.value : ''"
            >
              {{ data.value | truncate(20, "...") }}
            </div>
          </template>
          <template #cell(empty)="data">
            <div class="d-flex justify-content-end">
              <b-button
                :data-cy="`test-edit-snapshot-button-${data.item.name}`"
                :disabled="
                  `${data.item.type}` === 'INITIAL' ||
                  loadingStatus ||
                  statusVariant(qre.status) === 'warning' ||
                  statusVariant(qre.status) === 'secondary'
                "
                class="ml-2"
                pill
                size="sm"
                variant="info"
                @click="modalEditSnapshotBtn(data.item)"
              >
                <b-icon-pencil />
              </b-button>

              <b-button
                v-b-modal.modal-center
                :data-cy="`restore-snapshot-button-${data.item.name}`"
                :disabled="
                  loadingStatus ||
                  statusVariant(qre.status) === 'warning' ||
                  statusVariant(qre.status) === 'secondary'
                "
                class="ml-2"
                pill
                size="sm"
                variant="success"
                @click="restoreSnapshotBtn(data.item.snapshotId)"
              >
                <b-icon-bootstrap-reboot />
              </b-button>

              <b-button
                v-b-modal.modal-center
                :data-cy="`delete-snapshot-button-${data.item.name}`"
                :disabled="
                  `${data.item.type}` === 'INITIAL' ||
                  loadingStatus ||
                  statusVariant(qre.status) === 'warning' ||
                  statusVariant(qre.status) === 'secondary'
                "
                class="ml-2"
                pill
                size="sm"
                variant="outline-danger"
                @click="deleteSnapshotBtn(data.item.snapshotId)"
              >
                <b-icon-trash />
              </b-button>
            </div>
          </template>
        </b-table>
        <b-card-text
          v-if="snapshots.length === 0 && !loading"
          class="text-center my-4"
        >
          {{ $t("singleQRE.emptyTable") }}
        </b-card-text>

        <b-card-text class="mt-3 text-right" data-cy="qre-limit-set">
          {{ noOfBackupSnapshots(snapshots) }} of {{ qre.snapshotLimit }}
          {{ $t("singleQRE.slots") }}
        </b-card-text>
      </b-card>
    </b-overlay>

    <SnapshotModalForSingleQRE
      v-bind:qre="qre"
      v-bind:snapshot-comment="snapshotComment"
      v-bind:snapshot-id="snapshotId"
      v-bind:snapshot-name="snapshotName"
      @getQreForSnapshotModal="getQreAgent"
    />

    <FileUploadModal v-bind:qre="qre" />
  </div>
</template>
<script>
import { app } from "@/main";
import SnapshotModalForSingleQRE from "@/components/Modals/SnapshotModal.vue";
import GpuIcon from "@/components/Icons/GpuIcon.vue";
import { coreFormatter, byteToValueFormatter } from "@/util/formatters";
import FileUploadModal from "@/components/Modals/FileUploadModal.vue";
import ManageUsersCard from "@/components/ManageUsersCard.vue";

export default {
  name: "singleQRE",
  components: {
    SnapshotModalForSingleQRE,
    GpuIcon,
    FileUploadModal,
    ManageUsersCard,
  },
  data() {
    return {
      gpuAlert: false,
      gpuAlertText: [],
      loading: true,
      loadingUserAccess: true,
      loadingConsoleAccess: true,
      loadingStatus: false,
      loadingSnapshots: true,
      loadingAccess: true,
      qre: {
        status: "UNDEFINED",
        latestError: null,
        gpus: [
          {
            name: "",
          },
        ],
      },
      agent: {},
      qreId: "",
      userAccess: [],
      consoleAccess: [],
      userAccessOptions: [],
      consoleAccessOptions: [],
      hasJupyterNotebookAccess: null,
      hasRescueConsoleAccess: null,
      value: [],
      snapshots: [],
      pageSize: 10,
      currentPage: 1,
      activeLoggedIn: [],
      fields: [
        { key: "name", label: "Snapshot Name", tdClass: "align-middle" },
        {
          key: "creationDate",
          label: "Creation Date",
          tdClass: "align-middle",
        },
        { key: "comment", label: "Comment", tdClass: "align-middle" },
        { key: "empty", label: "", tdClass: "align-middle" },
      ],
      fieldsUsers: [
        { key: "trusteeActiveLoggedIn", label: "User" },
        {
          key: "active",
          label: "Active",
          tdClass: "text-center",
          thClass: "text-center",
          thAttr: {
            id: "id-active",
          },
        },
        {
          key: "loggedIn",
          label: "Logged In",
          tdClass: "text-center",
          thClass: "text-center",
          thAttr: {
            id: "id-loggedin",
          },
        },
      ],
      fieldsGPUs: [
        {
          key: "name",
          label: "Name",
          tdClass: "align-middle",
        },
        {
          key: "status",
          label: "Status",
          thStyle: {
            width: "100px",
            minWidth: "100px !important",
          },
          tdClass: "align-middle",
        },
        {
          key: "empty",
          label: "",
          thStyle: { width: "50px", minWidth: "50px !important" },
          tdClass: "align-middle",
        },
      ],
      snapshotName: "",
      snapshotComment: "",
      snapshotId: "",
      valueName: "",
      valueComment: "",
    };
  },
  async created() {
    await this.getQreAgent();
    await this.getUserAccess();
    await this.getConsoleAccess();
    await this.getActiveLoggedInUsers();
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    // get QREs and Agent
    async getQreAgent() {
      this.loading = true;
      this.loadingSnapshots = true;

      try {
        const { data: qre } = await this.$http.get(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.$route.params.id}`,
          {
            errors: {
              404: () => true,
            },
          }
        );
        const { data: agent } = await this.$http.get(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/agents/${qre.agentId}`
        );

        this.gpuAlertText = qre.gpus.map((el) => {
          if (el.status === "BROKEN") {
            this.gpuAlert = true;

            return `GPU ${el.gpuDeviceId}: ${el.name} has disconnected. Please unassigned it!`;
          }
        });

        this.agent = agent;
        this.qreId = qre.qreId;
        this.hasJupyterNotebookAccess = qre.hasJupyterNotebookAccess;
        this.hasRescueConsoleAccess = qre.hasRescueConsoleAccess;

        this.snapshots = qre.snapshots.map((snapshot) => {
          return {
            ...snapshot,
            creationDate: new Date(snapshot.creationDate)
              .toLocaleString("en-GB")
              .replaceAll("/", "-"),
          };
        });

        this.setQreData(qre);
      } catch (err) {
        if (err.response.status === 404) {
          await this.$router.push("/admin/qres");
        }
        if (err.response) console.log(err.response);
      } finally {
        this.loading = false;
        this.loadingSnapshots = false;
      }
    },

    deleteModal() {
      this.$bvModal
        .msgBoxConfirm("Are you sure that you want to delete this QRE?", {
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            app.$bvToast.toast("Deleting Qre...", {
              title: `In progress`,
              toaster: "b-toaster-bottom-right",
              variant: "warning",
              solid: true,
            });
            this.deleteQre();
            this.$router.push({ path: "/admin/qres" });
          }
        })
        .catch((err) => {
          if (err.response) console.log(err.response);
        });
    },

    async deleteQre() {
      try {
        await this.$http.delete(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.$route.params.id}`
        );
        this.$bvToast.toast("Qre deleted!", {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    async changeQreStatus() {
      this.loadingStatus = true;
      try {
        this.qre.status = "TRANSITIONING";
        const { data } = await this.$http.put(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.$route.params.id}/status`,
          {
            errors: {
              404: () => true,
            },
          }
        );
        this.setQreData(data);
        this.$root.$bvToast.toast(`QRE ${data.status.toLowerCase()}!`, {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
      } catch (err) {
        if (err.response.status === 404) {
          await this.$router.push("/admin/qres");
        }
        if (err.response) {
          this.qre.status = "UNKNOWN";
          console.log(err.response);
        }
      } finally {
        this.loadingStatus = false;
      }
    },

    async refreshStatus() {
      try {
        const {
          data: { status },
        } = await this.$http.get(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.$route.params.id}/status`,
          {
            errors: {
              404: () => true,
            },
          }
        );
        this.qre.status = status;
      } catch (err) {
        if (err.response.status === 404) {
          await this.$router.push("/admin/qres");
        }
        if (err.response) {
          console.log(err.response);
        }
      }
    },

    async restartQre(qre) {
      this.qre = qre;
      this.loadingStatus = true;
      try {
        this.qre.status = "TRANSITIONING";
        await this.$http.put(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qre/${qre.qreId}/restart`,
          {
            errors: {
              404: () => true,
            },
          }
        );
        this.getQreAgent();
        this.$root.$bvToast.toast(`QRE restarted!`, {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
      } catch (err) {
        if (err.response.status === 404) {
          await this.$router.push("/admin/qres");
        }
        if (err.response) {
          this.qre.status = "UNKNOWN";
          console.log(err.response);
        }
      } finally {
        this.loadingStatus = false;
      }
    },

    setQreData(data) {
      this.qre = data;

      if (data.transitioning) {
        this.qre.status = "TRANSITIONING";
      }

      this.qre.cores = coreFormatter(data.cores);
      this.qre.memoryInBytes = byteToValueFormatter(data.memoryInBytes, true);
      this.qre.shmInBytes = byteToValueFormatter(data.shmInBytes, true);

      this.qre.storageInBytes =
        data.storageInBytes === 0 || data.storageInBytes === null
          ? "UNLIMITED"
          : byteToValueFormatter(data.storageInBytes, true);

      this.qre.cpuSetCpus =
        data.cpuSetCpus.length > 0 ? data.cpuSetCpus : "Not limited";
    },

    statusVariant(status) {
      switch (status.toUpperCase()) {
        case "RUNNING":
          return "success";
        case "STOPPED":
          return "danger";
        case "TRANSITIONING":
          return "warning";
        case "UNKNOWN":
        default:
          return "secondary";
      }
    },

    statusText(status) {
      return status.toUpperCase();
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },

    async getActiveLoggedInUsers() {
      this.loadingAccess = true;
      try {
        const {
          data: { trusteesActivity },
        } = await this.$http.get(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.$route.params.id}/trustees/activity`,
          {
            errors: {
              404: () => true,
            },
          }
        );

        for (const [key, value] of Object.entries(trusteesActivity)) {
          this.activeLoggedIn.push({
            trusteeActiveLoggedIn: key,
            active: value.active,
            loggedIn: value.loggedIn,
          });
        }
      } catch (err) {
        if (err.response.status === 404) {
          await this.$router.push("/admin/qres");
        }
        if (err.response) console.log(err.response);
      } finally {
        this.loadingAccess = false;
      }
    },

    // get Users that have access to this qre
    // get list of users that you can give permission to the qre

    async getUserAccess() {
      this.loadingUserAccess = true;
      try {
        const {
          data: { content },
        } = await this.$http.get(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.$route.params.id}/trustees`,
          {
            errors: {
              404: () => true,
            },
          }
        );

        const {
          data: { trustees },
        } = await this.$http.get(`${this.$cfg.BASE_IDENTITY_URL}/v0/trustees`, {
          errors: {
            404: () => true,
          },
        });

        this.userAccess = [];
        this.userAccessOptions = [];

        content.forEach((el) => {
          if (el.hasJupyterNotebookAccess) {
            this.userAccess.push(
              JSON.stringify({
                id: el.id,
                trustee: el.trustee,
                trusteeDisplay: el.trusteeDisplay,
                trusteeType: el.trusteeType,
              })
            );
          }
        });

        trustees.forEach((el) => {
          this.userAccessOptions.push({
            trustee: el.trustee,
            trusteeDisplay: el.trusteeDisplay ?? el.trustee,
            trusteeType: el.trusteeType,
          });
        });
      } catch (err) {
        if (err.response.status === 404) {
          await this.$router.push("/admin/qres");
        }
        if (err.response) console.log(err.response);
      } finally {
        this.loadingUserAccess = false;
      }
    },

    async getConsoleAccess() {
      this.loadingConsoleAccess = true;
      try {
        const {
          data: { content },
        } = await this.$http.get(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.$route.params.id}/trustees`,
          {
            errors: {
              404: () => true,
            },
          }
        );
        const {
          data: { trustees },
        } = await this.$http.get(`${this.$cfg.BASE_IDENTITY_URL}/v0/trustees`, {
          errors: {
            404: () => true,
          },
        });

        this.consoleAccess = [];
        this.consoleAccessOptions = [];

        content.forEach((el) => {
          if (el.hasRescueConsoleAccess && el.hasJupyterNotebookAccess) {
            this.consoleAccess.push(
              JSON.stringify({
                id: el.id,
                trustee: el.trustee,
                trusteeDisplay: el.trusteeDisplay,
                trusteeType: el.trusteeType,
              })
            );
          }
        });

        trustees.forEach((el) => {
          this.userAccess.forEach((elAcc) => {
            const elAccObj = JSON.parse(elAcc);
            if (elAccObj.trustee === el.trustee) {
              this.consoleAccessOptions.push({
                trustee: el.trustee,
                trusteeDisplay: el.trusteeDisplay ?? el.trustee,
                trusteeType: el.trusteeType,
              });
            }
          });
        });
      } catch (err) {
        if (err.response.status === 404) {
          await this.$router.push("/admin/qres");
        }
        if (err.response) console.log(err.response);
      } finally {
        this.loadingConsoleAccess = false;
      }
    },

    async giveQreAccess(option) {
      this.loadingUserAccess = true;
      this.loadingConsoleAccess = true;

      // add user access to Qre
      try {
        await this.$http.post(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.$route.params.id}/trustees/assign`,
          {
            right: "DELETE",
            trustee: option.trustee,
            trusteeDisplay: option.trusteeDisplay,
            trusteeType: option.trusteeType,
          }
        );
        this.search = "";
        await this.getUserAccess();
        await this.getConsoleAccess();
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    async giveConsoleAccess(option) {
      this.loadingConsoleAccess = true;

      // add user access to console
      try {
        await this.$http.post(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.$route.params.id}/trustees/assign`,
          {
            right: "DELETE",
            trustee: option.trustee,
            trusteeDisplay: option.trusteeDisplay,
            trusteeType: option.trusteeType,
            subresources: [
              {
                accessTypes: [
                  {
                    name: "Access",
                  },
                ],
                name: "Console",
              },
            ],
          }
        );
        this.search = "";
        await this.getConsoleAccess();
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    async removeQreAccess(tag) {
      this.loadingUserAccess = true;
      this.loadingConsoleAccess = true;

      // remove user rights to Qre
      try {
        const option = JSON.parse(tag);
        await this.$http.post(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.$route.params.id}/trustees/revoke`,
          {
            right: "DELETE",
            trustee: option.trustee,
            trusteeDisplay: option.trusteeDisplay,
            trusteeType: option.trusteeType,
          }
        );
        this.search = "";
        await this.getUserAccess();
        await this.getConsoleAccess();
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    async removeConsoleAccess(tag) {
      this.loadingConsoleAccess = true;

      // revoke user rights to Console
      try {
        const option = JSON.parse(tag);
        await this.$http.post(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.$route.params.id}/trustees/revoke`,
          {
            right: "DELETE",
            trustee: option.trustee,
            trusteeDisplay: option.trusteeDisplay,
            trusteeType: option.trusteeType,
            subresources: [
              {
                accessTypes: [
                  {
                    name: "Access",
                  },
                ],
                name: "Console",
              },
            ],
          }
        );
        await this.getConsoleAccess();
        this.search = "";
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    deleteSnapshotBtn(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure that you want to delete this Snapshot?", {
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            app.$bvToast.toast("Deleting Snapshot...", {
              title: `In progress`,
              toaster: "b-toaster-bottom-right",
              variant: "warning",
              solid: true,
            });
            this.deleteSnapshot(id);
          }
        })
        .catch((err) => {
          if (err.response) console.log(err.response);
        });
    },

    async deleteSnapshot(id) {
      this.loadingSnapshots = true;
      try {
        await this.$http.delete(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.qre.qreId}/snapshot/${id}`
        );
        this.$bvToast.toast("Snapshot deleted!", {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
        await this.getQreAgent();
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loadingSnapshots = false;
      }
    },

    resetSnapshotFormModal() {
      this.snapshotName = "";
      this.snapshotComment = "";
      this.snapshotId = "";
      this.$bvModal.show("singleQreModal");
    },

    async delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    async loop() {
      while (this.qre.status !== "RUNNING") {
        await this.refreshStatus();
        await this.delay(process.env.VUE_APP_RESTORE_SNAPSHOT_POOLING_INTERVAL);
      }
    },

    async restoreSnapshotBtn(snapshotId) {
      this.loadingSnapshots = true;
      try {
        const { data } = await this.$http.post(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.qre.qreId}/restore`,
          {
            snapshotId: snapshotId,
          }
        );
        this.qre.status = data;
        await this.loop();
        await this.getQreAgent();
        this.loadingSnapshots = false;
        this.$bvToast.toast("Snapshot restored!", {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
      } catch (err) {
        if (err.response) {
          console.log(err.response);
          app.$bvToast.toast("Error during snapshot restore!", {
            title: `Error`,
            toaster: "b-toaster-bottom-right",
            variant: "warning",
            solid: true,
          });
        }
      }
    },

    modalEditSnapshotBtn(snapshotInfo) {
      this.snapshotId = snapshotInfo.snapshotId;
      this.snapshotName = snapshotInfo.name;
      this.snapshotComment = snapshotInfo.comment;
      this.$bvModal.show("singleQreModal");
    },

    noOfBackupSnapshots(snapshots) {
      return snapshots.filter((s) => s.type === "BACKUP").length;
    },

    openModalForFileUpload() {
      this.$bvModal.show("fileUploadModal");
    },
  },
};
</script>

<style scoped>
.card-info {
  font-size: 1.5rem;
  font-weight: 700;
  color: #6c757d;
  min-height: 36px;
}

.gpu-name {
  font-weight: 700;
  color: #6c757d;
}

.custom-card-title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  color: #7e868b;
}

#dropdown-group-1 {
  max-height: 350px;
  overflow-y: auto;
}

.agent-link {
  text-decoration: none;
}
</style>
